import React from 'react'
import './CopyrightFooter.css'

const CopyrightFooter = () => {
  return (
    <div>
      <footer className="footer">
        <p>&copy; 2025 JointWorks Physical Therapy, All Rights Reserved.</p>
      </footer>
    </div>
  )
}

export default CopyrightFooter